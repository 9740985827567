import React, { useState } from 'react';
import { ArwesThemeProvider, StylesBaseline } from '@arwes/core';
import Sidebar from './components/sidebar/sidebar.component';
import RankList from './components/ranklist/ranklist.component';
import 'bootstrap/dist/css/bootstrap.min.css';

import tokensByID from './sortedByTokenID.json';

import './App.scss';

const ROOT_FONT_FAMILY = '"Titillium Web", sans-serif';

const App = () => {
  const [checkedItems, setCheckedItems] = useState({}); //plain object as state

  var sortedByRank = [...tokensByID].sort(function (a, b) {
    return b.points - a.points;
  });

  const handleCheckboxChange = event => {
    if (event === 'reset') {
      setCheckedItems({});
    } else {
      // updating an object instead of a Map
      setCheckedItems({
        ...checkedItems,
        [event.target.name]: event.target.checked
      });
    }
  };

  React.useEffect(() => {}, [checkedItems]);

  const filteredData = () => {
    const filterItems = Object.entries(checkedItems)
      .filter(x => x[1])
      .map(t => t[0]);

    if (filterItems.length) {
      return sortedByRank.filter(nft =>
        nft.attributes.some(att => filterItems.includes(att.value))
      );
    } else {
      return sortedByRank;
    }
  };

  return (
    <ArwesThemeProvider>
      <StylesBaseline styles={{ body: { fontFamily: ROOT_FONT_FAMILY } }} />
      <div className='wrapper'>
        <Sidebar
          filterCallback={handleCheckboxChange}
          checkedItems={checkedItems}
        />
        <div className='main'>
          <RankList data={filteredData()} />
        </div>
      </div>
    </ArwesThemeProvider>
  );
};
export default App;
