import { LoadingBars } from '@arwes/core';
import React from 'react';
import blankLoading from './../../assets/images/blankloading.png';

class NftImageLoader extends React.Component {
  constructor(props) {
    super(props);
    this.state = { imageStatus: 'loading' };
  }

  handleImageLoaded() {
    this.setState({ imageStatus: 'loaded' });
  }

  handleImageErrored() {
    this.setState({ imageStatus: 'failed to load' });
  }

  render() {
    return (
      <div style={{ maxHeight: 170 }}>
        {this.state.imageStatus === 'loading' && !this.props.showOutline ? (
          <div className='loadingBars'>
            <LoadingBars animator={true} />
          </div>
        ) : (
          ''
        )}
        {this.state.imageStatus === 'loading' && this.props.showOutline ? (
          <img
            src={blankLoading}
            alt={this.props.imageAlt}
            style={{ maxHeight: '150px' }}
          />
        ) : (
          ''
        )}
        <img
          src={this.props.imageUrl}
          onLoad={this.handleImageLoaded.bind(this)}
          onError={this.handleImageErrored.bind(this)}
          alt={this.props.imageAlt}
          style={
            this.state.imageStatus === 'loading'
              ? { visibility: 'hidden' }
              : { visibility: 'unset' }
          }
        />
      </div>
    );
  }
}
export default NftImageLoader;
