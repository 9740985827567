import React, { useEffect } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import InfiniteScroll from 'react-infinite-scroll-component';
import NftCard from '../nftcard/nftcard.component';
import NftModal from '../modal/nftmodal.component';
import './ranklist.scss';

const RankList = ({ data }) => {
  const [modalShow, setModalShow] = React.useState(false);
  const [selectedID, setSelectedID] = React.useState(false);

  const [count, setCount] = React.useState({
    prev: 0,
    next: 24
  });
  const [hasMore, setHasMore] = React.useState(true);
  const [current, setCurrent] = React.useState(
    data.slice(count.prev, count.next)
  );

  useEffect(() => {
    //do reset
    const resetUI = () => {
      setCurrent(data.slice(0, 24));
      setCount({
        prev: 0,
        next: 24
      });
    };
    return resetUI();
  }, [data]);

  const getMoreData = () => {
    if (current.length === data.length) {
      setHasMore(false);
      return;
    }
    setTimeout(() => {
      setCurrent(current.concat(data.slice(count.prev + 24, count.next + 24)));
    }, 2000);
    setCount(prevState => ({
      prev: prevState.prev + 24,
      next: prevState.next + 24
    }));
  };

  const handleCallback = props => {
    //console.log('Clicked!', props);
    setSelectedID(props);
    setModalShow(true);
  };

  //console.log(data);

  return (
    <div className='ranklist'>
      <div className='ranklist__results'>
        <Container key={data.length}>
          <InfiniteScroll
            dataLength={current.length}
            next={getMoreData}
            hasMore={hasMore}
            loader={<div></div>}
          >
            <Row>
              {current &&
                current.map(nft => {
                  return (
                    <Col
                      key={`col_${nft.token_id}`}
                      lg={2}
                      md={3}
                      sm={6}
                      xs={12}
                    >
                      <div
                        key={`div_${nft.token_id}`}
                        style={{ marginBottom: 25 }}
                      >
                        <NftCard
                          key={nft.token_id}
                          modalCallback={handleCallback}
                          details={nft}
                        />
                      </div>
                    </Col>
                  );
                })}
            </Row>
          </InfiniteScroll>
        </Container>
      </div>
      <NftModal
        show={modalShow}
        onHide={() => setModalShow(false)}
        selected={selectedID}
      />
    </div>
  );
};

export default RankList;
