import { AnimatorGeneralProvider } from '@arwes/animation';
import { Text } from '@arwes/core';
import React from 'react';
import NftImageLoader from './../modal/nftimage.component';

const IMAGE_URL = 'https://api.astrobros-nft.com/bros/imgs/avatar/3590-l.jpg';
const SOUND_OBJECT_URL = '/assets/sounds/object.mp3';
const SOUND_ASSEMBLE_URL = '/assets/sounds/assemble.mp3';
const SOUND_TYPE_URL = '/assets/sounds/type.mp3';
const SOUND_CLICK_URL = '/assets/sounds/click.mp3';

const animatorGeneral = { duration: { enter: 200, exit: 200, stagger: 30 } };
const audioSettings = { common: { volume: 0.25 } };
const playersSettings = {
  object: { src: [SOUND_OBJECT_URL] },
  assemble: { src: [SOUND_ASSEMBLE_URL], loop: true },
  type: { src: [SOUND_TYPE_URL], loop: true },
  click: { src: [SOUND_CLICK_URL] }
};
const bleepsSettings = {
  object: { player: 'object' },
  assemble: { player: 'assemble' },
  type: { player: 'type' },
  click: { player: 'click' }
};

function NftCard({ modalCallback, details }) {
  const { avatar, attributes, image, rank, token_id, points } = details;

  return (
    <div>
      <div className='nft-card'>
        <div className='nft-card__rank'>
          <h1>#{rank}</h1>
        </div>

        <div
          className='nft-card__picture'
          onClick={() => modalCallback(details)}
        >
          {/* <img src={avatar.avatar_l} alt='' /> */}
          <NftImageLoader
            imageUrl={avatar.avatar_l}
            imageAlt={avatar.avatar_l}
            showOutline={true}
          />
        </div>
        <div className='nft-card__name'>
          <h3>Astrobro #{token_id}</h3>
        </div>

        <div className='nft-card__price'>0.25 ETH</div>
      </div>
    </div>
  );
}

export default NftCard;
