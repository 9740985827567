import { Animator, AnimatorGeneralProvider } from '@arwes/animation';
import { Text, Button, FrameCorners } from '@arwes/core';
import { BleepsProvider } from '@arwes/sounds';
import React, { useState } from 'react';
import './sidebar.scss';
import { Accordion, Container, Row, Col } from 'react-bootstrap';
import traitOdds from './traitOdds.json';

//images
import logo from './../../assets/images/logo-animated.gif';
import background from './../../assets/images/space-bg.gif';

//@ts-check

const SOUND_OBJECT_URL =
  'https://playground.arwes.dev/assets/sounds/object.mp3';
const SOUND_TYPE_URL = 'https://playground.arwes.dev/assets/sounds/type.mp3';
const audioSettings = { common: { volume: 0.25 } };
const playersSettings = {
  object: { src: [SOUND_OBJECT_URL] },
  type: { src: [SOUND_TYPE_URL], loop: true }
};
const bleepsSettings = {
  object: { player: 'object' },
  type: { player: 'type' }
};
const generalAnimator = { duration: { enter: 200, exit: 200 } };

// console.log(traitOdds);

const Sidebar = ({ filterCallback, checkedItems }) => {
  const { Traits } = traitOdds;

  const genCheckboxes = traitName => {
    return (
      <Container>
        <Row>
          {Object.entries(Traits[traitName])
            .filter(t => t[0] !== 'total')
            .map(trait => {
              const traitName = trait[0];
              return (
                <Col sm={6} key={traitName + 1}>
                  <label>
                    <input
                      type='checkbox'
                      name={traitName}
                      id={traitName}
                      checked={checkedItems[traitName] || ''}
                      onChange={filterCallback}
                    />
                    <span>{traitName}</span>
                  </label>
                </Col>
              );
            })}
        </Row>
      </Container>
    );
  };

  const [activate, setActivate] = useState(true);

  console.log('Sidebar Checekd', Object.entries(checkedItems));

  const handleResetFilters = () => {};
  return (
    <div
      className='sidebar'
      style={{
        backgroundImage: 'url(' + background + ')'
      }}
    >
      <div className='sidebar__content'>
        <BleepsProvider
          audioSettings={audioSettings}
          playersSettings={playersSettings}
          bleepsSettings={bleepsSettings}
        >
          <AnimatorGeneralProvider animator={generalAnimator}>
            <Animator animator={{ activate, manager: 'stagger' }}>
              <img src={logo} alt='' srcset='' />
            </Animator>
          </AnimatorGeneralProvider>
        </BleepsProvider>
        <div className='sidebar__overlay'>
          {Object.entries(checkedItems).filter(x => x[1]).length ? (
            <Button
              FrameComponent={FrameCorners}
              onClick={() => filterCallback('reset')}
              style={{ marginBottom: 15, marginTop: 15 }}
            >
              Reset Filters
            </Button>
          ) : (
            ''
          )}
          <Accordion>
            <Accordion.Item eventKey='0'>
              <Accordion.Header>Arm Right</Accordion.Header>
              <Accordion.Body>{genCheckboxes('arm-r')}</Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey='1'>
              <Accordion.Header>Back</Accordion.Header>
              <Accordion.Body>{genCheckboxes('back')}</Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey='2'>
              <Accordion.Header>Background</Accordion.Header>
              <Accordion.Body>{genCheckboxes('bg')}</Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey='3'>
              <Accordion.Header>Body</Accordion.Header>
              <Accordion.Body>{genCheckboxes('body')}</Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey='4'>
              <Accordion.Header>Body Lower</Accordion.Header>
              <Accordion.Body>{genCheckboxes('body-lower')}</Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey='5'>
              <Accordion.Header>Body Upper</Accordion.Header>
              <Accordion.Body>{genCheckboxes('body-upper')}</Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey='6'>
              <Accordion.Header>Face Accs</Accordion.Header>
              <Accordion.Body>{genCheckboxes('face-accs')}</Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey='7'>
              <Accordion.Header>Glasses</Accordion.Header>
              <Accordion.Body>{genCheckboxes('glass-special')}</Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey='8'>
              <Accordion.Header>Hair</Accordion.Header>
              <Accordion.Body>{genCheckboxes('hair')}</Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey='9'>
              <Accordion.Header>Hat</Accordion.Header>
              <Accordion.Body>{genCheckboxes('hat')}</Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey='10'>
              <Accordion.Header>Head</Accordion.Header>
              <Accordion.Body>{genCheckboxes('head')}</Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey='11'>
              <Accordion.Header>Head Accs</Accordion.Header>
              <Accordion.Body>{genCheckboxes('head-accs')}</Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey='12'>
              <Accordion.Header>Horn</Accordion.Header>
              <Accordion.Body>{genCheckboxes('horn')}</Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey='13'>
              <Accordion.Header>Mouth Accs</Accordion.Header>
              <Accordion.Body>{genCheckboxes('mouth-accs')}</Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey='14'>
              <Accordion.Header>Orientation</Accordion.Header>
              <Accordion.Body>{genCheckboxes('orientation')}</Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey='15'>
              <Accordion.Header>Texture #1</Accordion.Header>
              <Accordion.Body>{genCheckboxes('texture')}</Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey='16'>
              <Accordion.Header>Texture #2</Accordion.Header>
              <Accordion.Body>{genCheckboxes('texture-2')}</Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
