import React from 'react';
import { Container, Modal, Row, Col } from 'react-bootstrap';
import { Button, Text, Blockquote, LoadingBars } from '@arwes/core';
import { AnimatorGeneralProvider, Animator } from '@arwes/animation';
import './nftmodal.scss';
import { BleepsProvider } from '@arwes/sounds';
import NftImageLoader from './nftimage.component';

const SOUND_ASSEMBLE_URL = '/assets/sounds/assemble.mp3';
const SOUND_TYPE_URL = '/assets/sounds/type.mp3';
const SOUND_CLICK_URL = '/assets/sounds/click.mp3';

const bleepsSettings = {
  assemble: { player: 'assemble' },
  type: { player: 'type' },
  click: { player: 'click' }
};
const audioSettings = { common: { volume: 0.25 } };
const playersSettings = {
  assemble: { src: [SOUND_ASSEMBLE_URL], loop: true },
  type: { src: [SOUND_TYPE_URL], loop: true },
  click: { src: [SOUND_CLICK_URL] }
};
const generalAnimator = { duration: { enter: 200, exit: 200 } };

const Attribute = ({ palette, name, value, traitScore }) => {
  const getColor = score => {
    const colorKeyHex = {
      common: '#F2F2F2',
      uncommon: '#16C60C',
      rare: '#0078D7',
      epic: '#886CE4',
      legendary: '#F7630C',
      artifact: '#FFB900'
    };

    if (traitScore <= 0.01) {
      return colorKeyHex.artifact;
    }
    if (traitScore <= 0.03) {
      return colorKeyHex.legendary;
    }
    if (traitScore <= 0.05) {
      return colorKeyHex.epic;
    }
    if (traitScore <= 0.2) {
      return colorKeyHex.rare;
    }
    if (traitScore <= 0.5) {
      return colorKeyHex.uncommon;
    }
    return colorKeyHex.common;
  };

  return (
    <Blockquote palette={palette}>
      <Text>
        <div className='attBlock'>
          <div className='attTitle'>[{name.toUpperCase()}]</div>
          <div
            className='attValue'
            style={{ color: `${getColor(traitScore)}` }}
          >
            {value}
          </div>
        </div>
      </Text>
    </Blockquote>
  );
};

function NftModal(props) {
  const [activate, setActivate] = React.useState(true);

  React.useEffect(() => {
    if (!activate) {
      setActivate(!activate);
    }

    return () => {
      setActivate(!activate);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activate, props.selected.token_id]);

  const handleClose = () => {
    setActivate(!activate);
    const timeout = setTimeout(() => props.onHide(), 200);
  };

  const { avatar, attributes, image, rank, token_id, points } = props.selected;

  return (
    <div className='nftmodal'>
      <Modal
        {...props}
        size='lg'
        aria-labelledby='contained-modal-title-vcenter'
        centered
        contentClassName='nftmodal'
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <AnimatorGeneralProvider animator={generalAnimator}>
            <div className='nftmodal__wrapper'>
              <div className='nftmodal__card'>
                <h4>Rarity Rank #{rank}</h4>
                <NftImageLoader
                  imageUrl={image}
                  imageAlt={`Rarity Rank #${rank}`}
                />
              </div>
              <div className='nftmodal__rarity'>
                <BleepsProvider
                  audioSettings={audioSettings}
                  playersSettings={playersSettings}
                  bleepsSettings={bleepsSettings}
                >
                  <Animator animator={{ activate, manager: 'stagger' }}>
                    <Text as='h1'>AstroBro #{token_id}</Text>
                    <Text as='p'>
                      A nebula is an interstellar cloud of dust, hydrogen,
                      helium and other ionized gases.
                    </Text>
                    <Container>
                      <Row>
                        {attributes &&
                          attributes.map((att, index) => (
                            <Col
                              key={`col_${token_id}_${index}`}
                              lg={6}
                              md={6}
                              sm={6}
                              xs={12}
                            >
                              <Attribute
                                key={`modal${token_id}${att.trait_type}`}
                                name={att.trait_type}
                                value={att.value}
                                traitScore={att.score}
                              />
                            </Col>
                          ))}
                      </Row>
                    </Container>
                    {/* <Example palette='secondary' />
                    <Example palette='success' />
                    <Example palette='success' />
                    <Example palette='success' /> */}
                  </Animator>
                </BleepsProvider>
              </div>
            </div>
          </AnimatorGeneralProvider>
        </Modal.Body>
        <Modal.Footer>
          <BleepsProvider
            audioSettings={audioSettings}
            playersSettings={playersSettings}
            bleepsSettings={bleepsSettings}
          >
            <AnimatorGeneralProvider
              animator={{ duration: { enter: 200, exit: 200 } }}
            >
              <Button animator={true} onClick={handleClose}>
                <Text>Close</Text>
              </Button>
            </AnimatorGeneralProvider>
          </BleepsProvider>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default NftModal;
